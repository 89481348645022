import type { GetStaticProps } from 'next';

import { makeMarketingPage } from '../modules/page-marketing/page-marketing';

import { getStaticProps as marketingPageGetStaticProps } from './[...slug]';

const headChildren = (
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "image": "https://uk.shellenergy.com/favicon.ico",
        "url": "https://uk.shellenergy.com",
        "sameAs": ["https://shellenergy.com", "https://www.shellenergy.co.uk"],
        "logo": "https://uk.shellenergy.com/favicon.ico",
        "name": "Shell Energy",
        "description": "Energy supply and solutions from Shell Energy. Flexible, fixed and passthrough gas, power and renewable energy for UK businesses.",
        "email": "SEUKL-contactus@shell.com",
        "telephone": "+44 330 094 5800",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Shell Energy, 586-592, Elder Gate",
          "addressLocality": "United Kingdom",
          "addressCountry": "UK",
          "addressRegion": "Milton Keynes",
          "postalCode": "MK9 1LR"
        },
        "vatID": "GB 340 0347 49",
        "iso6523Code": "_"
      }
`}
  </script>
);

const MarketingPage = makeMarketingPage(headChildren);
export default MarketingPage;

export const getStaticProps: GetStaticProps = ({ ...rest }) =>
  marketingPageGetStaticProps({ ...rest, params: { slug: ['home'] } });
