import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import React from 'react';

import { Container, Main, NextImage } from '@seuk/design-system/atoms';
import { Breadcrumbs } from '@seuk/design-system/molecules';

import { FormContainer, Hero, HeroQuestion } from '../../components';
import { HeroDetails } from '../../components/hero/hero-details';
import { MarketingSection } from '../../components/marketing/marketing-section';

import type { MarketingPageProps } from './types';

export default function MarketingPage({
  headChildren,
  hero,
  introBackgroundColor,
  introDescription,
  introImage,
  introTitle,
  sections,
  seo,
  name,
  slug,
}: MarketingPageProps) {
  const hasIntro = introDescription || introImage || introTitle;
  const heroQuestionWithContent = ({ hasContainer }: { hasContainer?: boolean }) => {
    if (!hasIntro) {
      return null;
    }

    const heroQuestionContent = (
      <>
        {introTitle && <h2>{introTitle}</h2>}
        {introImage && <NextImage {...introImage} />}
        {introDescription && <p>{introDescription}</p>}
      </>
    );

    return (
      <HeroQuestion backgroundColor={introBackgroundColor}>
        {hasContainer ? <Container>{heroQuestionContent}</Container> : <>{heroQuestionContent}</>}
      </HeroQuestion>
    );
  };
  const isHomePage = slug === 'home';

  const breadcrumbItems = React.useMemo(() => {
    return [{ label: 'Home', href: '/' }, { label: name }];
  }, [name]);

  return (
    <>
      {headChildren && <Head>{headChildren}</Head>}

      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={{ images: [{ url: typeof hero.image.src === 'string' ? hero.image.src : undefined }] }}
        nofollow={seo?.nofollow}
        noindex={seo?.noindex}
      />

      <Main data-testid="home-page">
        {!isHomePage && (
          <Container>
            <Breadcrumbs items={breadcrumbItems} backHref="/" />
          </Container>
        )}
        <Hero heroImage={hero.image} bgAlign={hero.imageAlignment} heroAltText={hero.imageAlt} id="hero">
          {hero.title && <HeroDetails title={hero.title} subtitle={hero.subtitle} link={hero.link} />}
        </Hero>

        {hasIntro && introBackgroundColor === 'Grey' ? (
          <FormContainer>{heroQuestionWithContent({ hasContainer: false })}</FormContainer>
        ) : (
          heroQuestionWithContent({ hasContainer: true })
        )}

        {sections?.map((section) => (
          <MarketingSection key={section.id} {...section} />
        ))}
      </Main>
    </>
  );
}

export function makeMarketingPage(
  headChildren?: React.ReactNode
): React.FC<InferGetStaticPropsType<GetStaticProps<MarketingPageProps>>> {
  return function CustomLegalPage(props) {
    return <MarketingPage {...props} headChildren={headChildren} />;
  };
}
